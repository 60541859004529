<template>
  <v-card style='padding: 10px'>
    <div class='title'>Latest Prescription:</div>
    <br />
    <v-text-field label='Review after:' readonly v-model='reviewAfter' />
    <br />
    <v-card-text>
      <v-data-table
        style='height: 300px;overflow: auto'
        :headers='headers'
        hide-default-footer
        :items='selectedDrugs'
        class='elevation-0'
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import WebH from '../../api/web';
export default {
  props: {
    pid: { type: Number, default: 0 }
  },
  computed: {
    drugs() {
      return this.$store.getters['DrugsStore/Drugs'];
    }
  },
  data() {
    return {
      loading: false,
      months: ['2 months', '4 months', '6 months', '8 months'],
      reviewAfter: '',
      selectedDrugs: [],
      headers: [
        {
          text: 'Name',
          value: 'Name',
          sortable: true,
          width: '20%'
        },
        {
          text: 'Composition',
          value: 'Composition',
          sortable: false,
          width: '30%'
        },
        { text: 'Dosage', value: 'Dosage', sortable: false, width: '50%' }
        // { text: 'Dose', value: 'Dosage', sortable: false, width: '10%' },
        // {
        //   text: 'Medicine Intake',
        //   value: 'Medicine',
        //   sortable: false,
        //   width: '30%'
        // }
      ]
    };
  },
  watch: {
    pid: function() {
      if (this.pid > 0) {
        this.getPrescription();
      }
    }
  },
  methods: {
    // displayMedicineIntake(item) {
    //   let str = '';
    //   str = item.MedicineIntakeTime === 0 ? 'Before food' : 'After food';
    //   str += ' ( ';
    //   if (item.OnMorning) str += ' Morning,';
    //   if (item.OnAfterNoon) str += ' Afternoon,';
    //   if (item.OnNight) str += ' Night ';
    //   str += ' )';
    //   return str;
    // },
    async getPrescription() {
      if (this.pid == null || this.pid <= 0) {
        return;
      }
      this.loading = true;
      this.selectedDrugs = [];
      let url = '/prescriptions/' + this.pid + '/latest';
      const res = await WebH.Get(url, 'latest prescription');
      if (res.Success) {
        if (res.Data === '') this.selectedDrugs = [];
        else {
          const presc = res.Data;
          this.reviewAfter = presc.ReviewAfter;
          for (let i = 0; i < presc.Medicines.Object.length; i++) {
            const drugId = presc.Medicines.Object[i].DrugId;
            const drug = this.drugs.find(x => x.Id === drugId); //this.$store.getters['DrugsStore/Drug'](drugId);
            if (drug === null) return;
            this.selectedDrugs.push({
              DrugId: drugId,
              Name: drug.Name,
              Composition: drug.Composition,
              Dosage: presc.Medicines.Object[i].Dosage,
              Dose: presc.Medicines.Object[i].Dose,
              MedicineIntakeTime: presc.Medicines.Object[i].MedicineIntakeTime,
              OnMorning: presc.Medicines.Object[i].OnMorning,
              OnAfterNoon: presc.Medicines.Object[i].OnAfterNoon,
              OnNight: presc.Medicines.Object[i].OnNight
            });
          }
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped />
