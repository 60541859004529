<template>
  <v-container
      :style='fixedHeight ? "height: 400px;overflow: auto" : ""'
      grild-list-xl
      fluid
  >
    <div>
      <v-layout row wrap>
        <v-flex sm12>
          <br/>
          <div class='headline font-weight-medium'>
            Treatments
          </div>
          <br/>
          <v-divider></v-divider>
          <br/>
        </v-flex>

        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Allergy to drugs
          </div>
          <v-text-field
              readonly
              label='Drugs:'
              v-model='moreProblems.Treatments.Object.drugAllergy'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Surgery history
          </div>
          <v-text-field
              readonly
              label='Surgery:'
              v-model='moreProblems.Treatments.Object.surgery'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Medication history
          </div>
          <v-text-field
              readonly
              label='BP:'
              v-model='moreProblems.Treatments.Object.medication.bp'
          ></v-text-field>
          <v-text-field
              readonly
              label='Diabetes:'
              v-model='moreProblems.Treatments.Object.medication.db'
          ></v-text-field>
          <v-text-field
              readonly
              label='Thyroid:'
              v-model='moreProblems.Treatments.Object.medication.th'
          ></v-text-field>
          <v-text-field
              readonly
              label='Others:'
              v-model='moreProblems.Treatments.Object.medication.others'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Dental workups
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Treatments.Object.dental.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Others:'
              v-model='moreProblems.Treatments.Object.dental.others'
          ></v-text-field>
        </v-flex>

        <v-flex sm12>
          <br/>
          <div class='headline font-weight-medium'>
            Family history
          </div>
          <br/>
          <v-divider></v-divider>
          <br/>
        </v-flex>

        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Mother
          </div>
          <v-text-field
              readonly
              label='Major illness:'
              v-model='moreProblems.Family.Object.mother.illness'
          ></v-text-field>
          <v-text-field
              readonly
              label='Allergies:'
              v-model='moreProblems.Family.Object.mother.allergies'
          ></v-text-field>
          <v-text-field
              readonly
              label='Surgery:'
              v-model='moreProblems.Family.Object.mother.surgery'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Father
          </div>
          <v-text-field
              readonly
              label='Major illness:'
              v-model='moreProblems.Family.Object.father.illness'
          ></v-text-field>
          <v-text-field
              readonly
              label='Allergies:'
              v-model='moreProblems.Family.Object.father.allergies'
          ></v-text-field>
          <v-text-field
              readonly
              label='Surgery:'
              v-model='moreProblems.Family.Object.father.surgery'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Grandparents
          </div>
          <v-text-field
              readonly
              label='Major illness:'
              v-model='moreProblems.Family.Object.grandparents.illness'
          ></v-text-field>
          <v-text-field
              readonly
              label='Allergies:'
              v-model='moreProblems.Family.Object.grandparents.allergies'
          ></v-text-field>
          <v-text-field
              readonly
              label='Surgery:'
              v-model='moreProblems.Family.Object.grandparents.surgery'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Siblings
          </div>
          <v-text-field
              readonly
              label='Major illness:'
              v-model='moreProblems.Family.Object.siblings.illness'
          ></v-text-field>
          <v-text-field
              readonly
              label='Allergies:'
              v-model='moreProblems.Family.Object.siblings.allergies'
          ></v-text-field>
          <v-text-field
              readonly
              label='Surgery:'
              v-model='moreProblems.Family.Object.siblings.surgery'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg6>
          <div class='subheading font-weight-medium'>
            Aunts/Uncles
          </div>
          <v-text-field
              readonly
              label='Major illness:'
              v-model='moreProblems.Family.Object.uncleAunt.illness'
          ></v-text-field>
          <v-text-field
              readonly
              label='Allergies:'
              v-model='moreProblems.Family.Object.uncleAunt.allergies'
          ></v-text-field>
          <v-text-field
              readonly
              label='Surgery:'
              v-model='moreProblems.Family.Object.uncleAunt.surgery'
          ></v-text-field>
        </v-flex>

        <v-flex sm12>
          <br/>
          <div class='headline font-weight-medium'>
            Habits
          </div>
          <br/>
          <v-divider></v-divider>
          <br/>
        </v-flex>

        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Smoking
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Habits.Object.smoking.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Per day:'
              v-model='moreProblems.Habits.Object.smoking.daily'
          ></v-text-field>
          <v-text-field
              readonly
              label='Started since (years):'
              v-model='moreProblems.Habits.Object.smoking.start'
          ></v-text-field>
          <v-text-field
              readonly
              label='Stopped since (years):'
              v-model='moreProblems.Habits.Object.smoking.stopped'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Alcohol drinking
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Habits.Object.alcohol.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Per day (ounce):'
              v-model='moreProblems.Habits.Object.alcohol.daily'
          ></v-text-field>
          <v-text-field
              readonly
              label='Started since (years):'
              v-model='moreProblems.Habits.Object.alcohol.start'
          ></v-text-field>
          <v-text-field
              readonly
              label='Stopped since (years):'
              v-model='moreProblems.Habits.Object.alcohol.stopped'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Travel extensively
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Habits.Object.travel.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Describe:'
              v-model='moreProblems.Habits.Object.travel.details'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Pets in your home
          </div>
          <v-text-field
              readonly
              label='Pets:'
              v-model='moreProblems.Habits.Object.pets'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Diet
          </div>
          <v-radio-group readonly v-model='moreProblems.Habits.Object.diet'>
            <v-radio label='Vegetarian' value='veg' color='primary'/>
            <v-radio label='Non vegetarian' value='nonVeg' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Average hours spent
          </div>
          <v-text-field
              readonly
              label='Home:'
              v-model='moreProblems.Habits.Object.timeSpent.home'
          ></v-text-field>
          <v-text-field
              readonly
              label='Work/School:'
              v-model='moreProblems.Habits.Object.timeSpent.work'
          ></v-text-field>
          <v-text-field
              readonly
              label='Others:'
              v-model='moreProblems.Habits.Object.timeSpent.other'
          ></v-text-field>
        </v-flex>

        <v-flex sm12>
          <br/>
          <div class='headline font-weight-medium'>
            Other details
          </div>
          <br/>
          <v-divider></v-divider>
          <br/>
        </v-flex>

        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            House location
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Others.Object.house.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Age of the house:'
              v-model='moreProblems.Others.Object.house.age'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Sense of smell
          </div>
          <v-radio-group readonly v-model='moreProblems.Others.Object.smell.choice'>
            <v-radio label='Good' value='aa' color='primary'/>
            <v-radio label='Above average' value='a' color='primary'/>
            <v-radio label='Below average' value='b' color='primary'/>
            <v-radio label='Not smelling' value='n' color='primary'/>
          </v-radio-group>
          <v-text-field
              readonly
              :disabled='moreProblems.Others.Object.smell.choice !== "n"'
              label='Not smelling since:'
              v-model='moreProblems.Others.Object.smell.since'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Allergy symptoms are more at
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Others.Object.location.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Describe:'
              v-model='moreProblems.Others.Object.location.details'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg3>
          <div class='subheading font-weight-medium'>
            Water leak in roof or walls
          </div>
          <v-radio-group readonly v-model='moreProblems.Others.Object.leak'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg3>
          <div class='subheading font-weight-medium'>
            Dust or insects in your surroundings
          </div>
          <v-radio-group readonly v-model='moreProblems.Others.Object.insects'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg3>
          <div class='subheading font-weight-medium'>
            Symptoms timing
          </div>
          <v-radio-group readonly v-model='moreProblems.Others.Object.time'>
            <v-radio label='Day' value='Day' color='primary'/>
            <v-radio label='Night' value='Night' color='primary'/>
            <v-radio label='Both' value='Both' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg3>
          <div class='subheading font-weight-medium'>
            Symptoms place
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Others.Object.place.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Symptoms are worse with
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Others.Object.worse.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-text-field
              readonly
              label='Others:'
              v-model='moreProblems.Others.Object.worse.others'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Symptoms month
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Others.Object.month.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Exposed to
          </div>
          <v-list>
            <v-list-item
                v-for='option in moreProblems.Others.Object.exposed.choices'
                :key='option'
            >
              <v-list-item-content>
                <v-list-item-title>&#x2713; {{ option }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-text-field
                readonly
                label='Others:'
                v-model='moreProblems.Others.Object.exposed.others'
            ></v-text-field>
          </v-list>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            Any observation in the living which influences your illness
          </div>
          <v-text-field
              readonly
              label='Describe:'
              v-model='moreProblems.Others.Object.influence'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            In past years have you suffered from skin,nose,lung,throat or
            stomach disorders?
          </div>
          <v-text-field
              readonly
              label='Describe:'
              v-model='moreProblems.Others.Object.past'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            Any hobbies (walking, indoor/outdoor games, chat etc)
          </div>
          <v-text-field
              readonly
              label='Hobbies:'
              v-model='moreProblems.Others.Object.hobbies.choices'
          ></v-text-field>
          <v-text-field
              readonly
              label='Describe if these hobbies induce allergy symptoms:'
              v-model='moreProblems.Others.Object.hobbies.details'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            Does dust,fumes,weather, food or any other which induce allergy
            symptoms
          </div>
          <v-text-field
              readonly
              label='Describe:'
              v-model='moreProblems.Others.Object.exposed.details'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            Do you dislike or avoid any food
          </div>
          <v-text-field
              readonly
              label='Food:'
              v-model='moreProblems.Others.Object.food.choices'
          ></v-text-field>
          <v-text-field
              readonly
              label='Why:'
              v-model='moreProblems.Others.Object.food.why'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            Have you stopped eating dishes like sour, spicy, sweet, bitter etc
          </div>
          <v-text-field
              readonly
              label='Dishes:'
              v-model='moreProblems.Others.Object.food.stopped'
          ></v-text-field>
          <v-text-field
              readonly
              label='Describe:'
              v-model='moreProblems.Others.Object.food.describe'
          ></v-text-field>
        </v-flex>

        <v-flex sm12>
          <br/>
          <div class='headline font-weight-medium'>
            For children
          </div>
          <br/>
          <v-divider></v-divider>
          <br/>
        </v-flex>

        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Problems during pre/post natal period or during delivery
          </div>
          <v-radio-group readonly v-model='moreProblems.Children.Object.delivery'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Child had any colic
          </div>
          <v-radio-group readonly v-model='moreProblems.Children.Object.colic'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Is the child is on full diet
          </div>
          <v-radio-group readonly v-model='moreProblems.Children.Object.delivery'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Did child's mother drink milk while nursing
          </div>
          <v-radio-group readonly v-model='moreProblems.Children.Object.nurse'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            Is/was the baby breast fed exclusively
          </div>
          <v-radio-group readonly v-model='moreProblems.Children.Object.fed.choice'>
            <v-radio label='Yes' value='yes' color='primary'/>
            <v-radio label='No' value='no' color='primary'/>
          </v-radio-group>
          <v-text-field
              readonly
              label='How long (months):'
              v-model='moreProblems.Children.Object.fed.months'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <div class='subheading font-weight-medium'>
            If breast fed is not exclusive
          </div>
          <v-text-field
              readonly
              label='Alternative food:'
              v-model='moreProblems.Children.Object.fedNo.choice'
          ></v-text-field>
          <v-text-field
              readonly
              label='How long (months):'
              v-model='moreProblems.Children.Object.fedNo.month'
          ></v-text-field>
          <v-text-field
              readonly
              label='Feeding solid food from:'
              v-model='moreProblems.Children.Object.fedNo.solidFrom'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            How many infections child has in
          </div>
          <v-text-field
              readonly
              label='last 3 months:'
              v-model='moreProblems.Children.Object.infections.months3'
          ></v-text-field>
          <v-text-field
              readonly
              label='last year:'
              v-model='moreProblems.Children.Object.infections.yr'
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg12>
          <div class='subheading font-weight-medium'>
            Additional information you like to inform in relation to allergy
            symptoms or any other disease
          </div>
          <v-text-field
              readonly
              label='Info:'
              v-model='moreProblems.Children.Object.otherInfo'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    fixedHeight: {
      type: Boolean,
      default: true
    },
    moreProblems: {
      type: Object,
      default: () => {
        return {
          Treatments: {
            Object: {
              drugAllergy: '',
              surgery: '',
              medication: {db: '', bp: '', th: '', others: ''},
              dental: {choices: [], others: ''}
            }
          },
          Family: {
            Object: {
              mother: {illness: '', surgery: '', allergies: ''},
              father: {illness: '', surgery: '', allergies: ''},
              grandparents: {illness: '', surgery: '', allergies: ''},
              siblings: {illness: '', surgery: '', allergies: ''},
              uncleAunt: {illness: '', surgery: '', allergies: ''}
            }
          },
          Habits: {
            Object: {
              smoking: {choices: [], daily: '', start: '', stopped: ''},
              alcohol: {choices: [], daily: '', start: '', stopped: ''},
              travel: {choices: [], details: ''},
              pets: '',
              diet: '',
              timeSpent: {home: '', work: '', other: ''}
            }
          },
          Others: {
            Object: {
              house: {choices: [], age: ''},
              smell: {choice: '', since: ''},
              location: {choices: [], details: ''},
              leak: '',
              insects: '',
              time: '',
              place: {choices: []},
              worse: {choices: [], others: ''},
              month: {choices: []},
              exposed: {choices: [], others: '', details: ''},
              influence: '',
              past: '',
              hobbies: {choices: '', details: ''},
              food: {choices: '', why: '', stopped: '', describe: ''}
            }
          },
          Children: {
            Object: {
              delivery: '',
              colic: '',
              diet: '',
              fed: {choice: '', months: ''},
              fedNo: {choice: '', month: '', solidFrom: ''},
              nurse: '',
              infections: {months3: '', yr: ''},
              otherInfo: ''
            }
          }
        };
      }
    }
  }
};
</script>

<style scoped></style>
