<template>
  <v-card>
    <v-card-title>
      <div class='headline'>
        Case sheet
      </div>
    </v-card-title>
    <v-card-text>
      <div>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>Patient Details:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <PatientSummary :patient='patient' :fixed-height='fixedHeight' />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Allergy Survey:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <AllergySummary :problems='problems' :fixed-height='fixedHeight' />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Patient Other Details:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <PatientHistorySummary
                  :moreProblems='moreProblems'
                  :fixed-height='fixedHeight'
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Exam Details:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <ExamSummary :exam='exam' :fixed-height='fixedHeight' />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Investigation Details:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <InvestSummary :invest='invest' :fixed-height='fixedHeight' />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Diagnoses:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <DiagnosisSummary
                  :diagnoses='diagnoses.JDiagnosis'
                  :fixed-height='fixedHeight'
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>SPT Details:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <SptSummary :spt='spt' :fixed-height='fixedHeight' />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Laboratory Details:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <IgESummary :ige='ige' :fixed-height='fixedHeight' />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card-text>

    <!--    <v-tabs dark show-arrows background-color='teal darken-3'>-->
    <!--      <template slot='prev-icon'>-->
    <!--        <v-icon class='filterIcon grey&#45;&#45;text text&#45;&#45;darken-1'-->
    <!--          >fa fa-angle-left</v-icon-->
    <!--        >-->
    <!--      </template>-->
    <!--      <template slot='next-icon'>-->
    <!--        <v-icon class='filterIcon grey&#45;&#45;text text&#45;&#45;darken-1'-->
    <!--          >fa fa-angle-right</v-icon-->
    <!--        >-->
    <!--      </template>-->
    <!--      <v-tabs-slider color='teal lighten-3'></v-tabs-slider>-->
    <!--      <v-tab href='#patient'>Patient Details</v-tab>-->
    <!--      <v-tab href='#allergy'>Allergy Survey</v-tab>-->
    <!--      <v-tab href='#moreAllergy'>More Allergy Details</v-tab>-->
    <!--      <v-tab href='#exam'>Examinations</v-tab>-->
    <!--      <v-tab href='#invest'>Investigations</v-tab>-->
    <!--      <v-tab href='#diagnosis'>Diagnosis</v-tab>-->
    <!--      <v-tab href='#spt'>SPT</v-tab>-->
    <!--      <v-tab href='#ige'>Specific IgE</v-tab>-->
    <!--      <v-tab-item value='patient'>-->
    <!--        <PatientSummary :patient='patient'></PatientSummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='allergy'>-->
    <!--        <AllergySummary :problems='problems'></AllergySummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='moreAllergy'>-->
    <!--        <PatientHistorySummary-->
    <!--          :moreProblems='moreProblems'-->
    <!--        ></PatientHistorySummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='exam'>-->
    <!--        <ExamSummary :exam='exam'></ExamSummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='invest'>-->
    <!--        <InvestSummary :invest='invest'></InvestSummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='diagnosis'>-->
    <!--        <DiagnosisSummary :diagnoses='diagnoses.JDiagnosis'></DiagnosisSummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='spt'>-->
    <!--        <SptSummary :spt='spt'></SptSummary>-->
    <!--      </v-tab-item>-->
    <!--      <v-tab-item value='ige'>-->
    <!--        <IgESummary :ige='ige'></IgESummary>-->
    <!--      </v-tab-item>-->
    <!--    </v-tabs>-->
  </v-card>
</template>

<script>
import PatientSummary from '../../components/patients/PatientSummary';
import AllergySummary from '../../components/forms/AllergySurverySummary';
import ExamSummary from '../../components/exam/ExamSummaryDtl';
import InvestSummary from '../../components/investigations/InvestSummary';
import SptSummary from '../../components/spt/SptSummary';
import IgESummary from '../../components/lab/IgESummary';
import PatientHistorySummary from '../../components/forms/PatientHistorySummary';
import DiagnosisSummary from '../../components/diagnoses/DiagnosesSummary';
export default {
  components: {
    PatientSummary,
    ExamSummary,
    InvestSummary,
    SptSummary,
    IgESummary,
    AllergySummary,
    PatientHistorySummary,
    DiagnosisSummary
  },
  props: {
    fixedHeight: {
      type: Boolean,
      default: true
    },
    patient: {
      type: Object,
      default: () => {
        return {};
      }
    },
    problems: {
      type: Object,
      default: () => {
        return { Problems: [] };
      }
    },
    moreProblems: {
      type: Object,
      default: () => {
        return {
          Treatments: {},
          Habits: {},
          Others: {},
          Family: {},
          Children: {}
        };
      }
    },
    exam: {
      type: Object,
      default: () => {
        return {};
      }
    },
    invest: {
      type: Object,
      default: () => {
        return {};
      }
    },
    diagnoses: {
      type: Object,
      default: () => {
        return {
          JDiagnosis: []
        };
      }
    },
    spt: {
      type: Object,
      default: () => {
        return {
          Control: { Erythema: '', Wheal: '' },
          Positive: { Erythema: '', Wheal: '' },
          Allergens: []
        };
      }
    },
    ige: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
