<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Case Sheet
        </div>
      </v-flex>
      <v-flex sm12 lg12 xs12>
        <v-layout row>
          <v-flex sm3>
            <v-text-field label='Patient Id:' v-model='patientId' />
          </v-flex>
          <v-flex>
            <v-btn color='primary' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 md12 lg12>
        <Summary
          :fixed-height='false'
          :patient='patient'
          :problems='problems'
          :more-problems='moreProblems'
          :exam='exam'
          :invest='invest'
          :diagnoses='diagnosis'
          :spt='spt'
          :ige='ige'
        />
        <br />
        <PrescSummary :pid='patient.Id' />
      </v-flex>
      <!--<v-flex xs12 sm12 lg12>-->
      <!--<PrescDtl></PrescDtl>-->
      <!--</v-flex>-->
    </v-layout>
  </v-container>
</template>

<script>
import PrescSummary from '../../components/prescirptions/PrescSummary';
import WebH from '../../api/web';
import Summary from '../../components/patients/PatientPrscDisplay';
export default {
  components: { PrescSummary, Summary },
  data() {
    return {
      loading: false,
      patientId: '',
      patient: {},
      problems: {},
      moreProblems: {},
      exam: {},
      invest: {},
      diagnosis: {},
      spt: {},
      ige: {}
    };
  },
  created() {
    this.patientId = this.$store.getters['SettingsStore/patientId'];
    this.searchPatient();
  },
  methods: {
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.$store.commit('SettingsStore/setPatientId', this.patientId);
      this.loading = true;
      this.patient = {};
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          await this.getRelatedData();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },
    async getRelatedData() {
      this.getAllergySurvey();
      this.getPatientHistory();
      this.getExam();
      this.getInvestigations();
      this.getDiagnosis();
      this.getSPTs();
      this.getIgE();
    },
    async getAllergySurvey() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.problems = {};
      let url = '/allergies/' + this.patient.Id + '/allergy';
      const res = await WebH.Get(url, 'allergy survey');
      if (res.Success) {
        if (res.Data === '') this.problems = {};
        else this.problems = res.Data;
      }
      this.loading = false;
    },
    async getPatientHistory() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.moreProblems = {};
      let url = '/PatientHistories/' + this.patient.Id + '/details';
      const res = await WebH.Get(url, 'more allergy details');
      if (res.Success) {
        if (res.Data === '') this.moreProblems = {};
        else this.moreProblems = res.Data;
      }
      this.loading = false;
    },
    async getExam() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.exam = {};
      let url = '/exams/' + this.patient.Id;
      const res = await WebH.Get(url, 'examination');
      if (res.Success) {
        if (res.Data === '') this.exam = {};
        else this.exam = res.Data;
      }
      this.loading = false;
    },
    async getInvestigations() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.invest = {};
      let url = '/invest/' + this.patient.Id;
      const res = await WebH.Get(url, 'investigations');
      if (res.Success) {
        if (res.Data === '') this.invest = {};
        else this.invest = res.Data;
      }
      this.loading = false;
    },
    async getDiagnosis() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.diagnosis = {};
      let url = '/Diagnoses/' + this.patient.Id;
      const res = await WebH.Get(url, 'diagnosis');
      if (res.Success) {
        if (res.Data === '') this.diagnosis = {};
        else this.diagnosis = res.Data;
      }
      this.loading = false;
    },
    async getSPTs() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.spt = {};
      let url = '/spt/' + this.patient.Id;
      const res = await WebH.Get(url, 'spts');
      if (res.Success) {
        if (res.Data === '') this.spt = {};
        else this.spt = res.Data;
      }
      this.loading = false;
    },
    async getIgE() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.ige = {};
      let url = '/ige/' + this.patient.Id;
      const res = await WebH.Get(url, 'specific ige report');
      if (res.Success) {
        if (res.Data === '') this.ige = {};
        else this.ige = res.Data;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped />
