<template>
  <v-container :style='fixedHeight ? "height: 400px;overflow: auto" : ""'>
    <v-text-field
      readonly
      v-model.number='exam.Height'
      label='Height (cms):'
    ></v-text-field>
    <v-text-field
      readonly
      v-model.number='exam.Weight'
      label='Weight (Kg):'
    ></v-text-field>
    <v-text-field
      readonly
      v-model.number='exam.PR'
      label='Pulse rate (bpm):'
    ></v-text-field>
    <v-text-field
      readonly
      v-model.number='exam.Temp'
      label='Temperature (Celsius):'
    ></v-text-field>
    <v-text-field
      readonly
      v-model='exam.BP'
      label='BP (high / low mmHg):'
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  props: {
    exam: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fixedHeight: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
