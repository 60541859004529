<template>
  <v-container :style='fixedHeight ? "height: 400px;overflow: auto" : ""'>
    <v-list>
      <v-list-item
          v-for="(item, i) in diagnoses"
          :key="i"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  props: {
    fixedHeight: {
      type: Boolean,
      default: true
    },
    diagnoses: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Diagnosis',
          value: 'Diagnosis',
          sortable: true,
          width: '70%'
        }
      ]
    };
  }
};
</script>

<style scoped></style>
