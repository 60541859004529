<template>
  <v-container :style='fixedHeight ? "height: 400px;overflow: auto" : ""'>
    <div>
      <div class='subheading'>PEFR:</div>
      <v-layout row wrap>
        <v-flex xs4 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.number='invest.PEFRPred'
            label='Pred:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.number='invest.PEFRPre'
            label='Pre:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.number='invest.PEFRPost'
            label='Post:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <v-text-field
      readonly
      v-model.number='invest.SpO2'
      label='SpO2 (%):'
    ></v-text-field>
    <div>
      <v-layout>
        <v-flex xs4>
          <v-text-field
            readonly
            v-model.trim='invest.CRP'
            label='CRP:'
          ></v-text-field>
        </v-flex>
        <v-flex>
          <v-radio-group v-model='invest.CRPSt' row readonly>
            <v-radio label='Positive' value='positive' color='primary' />
            <v-radio label='Negative' value='negative' color='primary' />
          </v-radio-group>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.Urea'
            label='Urea breath test:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.Skin'
            label='Skin-KOH:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.Sweat'
            label='Sweat chloride test:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.Nasal'
            label='Nasal flow rate:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>

    <div>
      <div class='subheading'>TFT:</div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.TFT_T3'
            label='T3:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.TFT_T4'
            label='T4:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.TFT_TSH'
            label='TSH:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.TFT_Sputum'
            label='Sputum:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <div class='subheading'>PFT:</div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.PFT_FVC'
            label='FVC:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.PFT_FEV1_Pre'
            label='FEV1 Pre:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.PFT_FEV1_Post'
            label='FEV1 Post:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%;'>
          <v-text-field
            readonly
            v-model.trim='invest.PFT_FEV1_FVC'
            label='FEV1/FVC:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>

    <v-text-field
      readonly
      v-model.trim='invest.DLco'
      label='DLco:'
    ></v-text-field>

    <div>
      <div class='subheading'>Mucous clearance test:</div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Mucous_R'
            label='R:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Mucous_L'
            label='L:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <div class='subheading'>Nasal smear:</div>
      <div class='body-2'>Rt:</div>
      <v-layout row wrap>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Nasal_Rt_N'
            label='N:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Nasal_Rt_L'
            label='L:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Nasal_Rt_E'
            label='E:'
          ></v-text-field
        ></v-flex>
      </v-layout>
      <div class='body-2'>Lt:</div>
      <v-layout row wrap>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Nasal_Lt_N'
            label='N:'
          ></v-text-field
        ></v-flex>
        <v-flex xs4 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Nasal_Lt_L'
            label='L:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Nasal_Lt_E'
            label='E:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <div class='subheading'>Conjunctiva smear:</div>
      <div class='body-2'>Rt:</div>
      <v-layout row wrap>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Conj_Rt_N'
            label='N:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Conj_Rt_L'
            label='L:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Conj_Rt_E'
            label='E:'
          ></v-text-field
        ></v-flex>
      </v-layout>
      <div class='body-2'>Lt:</div>
      <v-layout row wrap>
        <v-flex xs4 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Conj_Lt_N'
            label='N:'
          ></v-text-field
        ></v-flex>
        <v-flex xs4 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Conj_Lt_L'
            label='L:'
          ></v-text-field>
        </v-flex>
        <v-flex xs4 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Conj_Lt_E'
            label='E:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>

    <div>
      <div class='subheading'>Thermo stimulatory test:</div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%'>
          <v-text-field
            readonly
            v-model.trim='invest.Th_Pressure'
            label='Pressure:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Th_Heat'
            label='Heat:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Th_Cold'
            label='Cold:'
          ></v-text-field>
        </v-flex>
        <v-flex xs6 style='padding: 1%'
          ><v-text-field
            readonly
            v-model.trim='invest.Th_Sweat'
            label='Sweat:'
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <v-layout row wrap>
        <v-flex xs6 style='padding: 1%;'
          ><v-text-field
            readonly
            v-model.trim='invest.Serum'
            label='Serum test:'
          ></v-text-field
        ></v-flex>
        <v-flex xs6 style='padding: 1%;'
          ><v-text-field
            v-model.trim='invest.ATgAB'
            readonly
            label='ATgAB:'
          ></v-text-field
        ></v-flex>
        <v-flex xs6 style='padding: 1%;'
          ><v-text-field
            readonly
            v-model.trim='invest.ATPO'
            label='ATPO:'
          ></v-text-field
        ></v-flex>
        <v-flex xs6 style='padding: 1%;'
          ><v-text-field
            readonly
            v-model.trim='invest.ANA'
            label='ANA:'
          ></v-text-field
        ></v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    invest: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fixedHeight: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
