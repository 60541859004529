<template>
  <v-container :style='fixedHeight ? "height: 400px;overflow: auto" : ""'>
    <v-layout row wrap v-if='spt.Control'>
      <v-flex xs6>Control</v-flex>
      <v-flex xs6>Positive</v-flex>
      <v-flex xs3 style='padding-right: 5px'>
        <v-text-field
          readonly
          label='Wheal (mm):'
          v-model.number='spt.Control.Wheal'
        ></v-text-field>
      </v-flex>
      <v-flex xs3 style='padding-left: 5px; padding-right: 5px'>
        <v-text-field
          readonly
          label='Erythema (mm):'
          v-model.number='spt.Control.Erythema'
        ></v-text-field>
      </v-flex>
      <!--<v-flex xs6>Positive</v-flex>-->
      <v-flex xs3 style='padding-left: 5px; padding-right: 5px'>
        <v-text-field
          readonly
          label='Wheal (mm):'
          v-model.number='spt.Positive.Wheal'
        ></v-text-field>
      </v-flex>
      <v-flex xs3 style='padding-left: 5px'>
        <v-text-field
          readonly
          label='Erythema (mm):'
          v-model.number='spt.Positive.Erythema'
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers='headers'
          :items='spt.Allergens'
          class='elevation-2'
          style='height: 200px;overflow: auto'
          item-key='Id'
        >
        </v-data-table>
      </v-flex>
    </v-layout>
    <div v-else>
      No SPT found
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    spt: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fixedHeight: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Allergen',
          value: 'Name',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Induration (mm)',
          value: 'Reading',
          sortable: true,
          width: '10%'
        }
      ]
    };
  }
};
</script>

<style scoped></style>
