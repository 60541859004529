<template>
  <v-container :style='fixedHeight ? "height: 400px;overflow: auto" : ""'>
    <v-layout row wrap>
      <v-flex xs12>
        <div class='title text-xs-center'>
          {{ ige.RTitle }}
        </div>
        <br />
        <hr />
        <br />
      </v-flex>
      <v-flex xs3>
        <div class='body-2'>
          Report Date:
        </div>
      </v-flex>
      <v-flex xs3>
        <div class='body-2 font-weight-regular'>
          {{ ige.SampleDate | FormatDateISO }}
        </div>
      </v-flex>
      <v-flex xs3>
        <div class='body-2'>
          Lab In-charge:
        </div>
      </v-flex>
      <v-flex xs3>
        <div class='body-2 font-weight-regular'>
          {{ ige.LabIn }}
        </div>
      </v-flex>
      <v-flex xs12>
        <br />
        <RepDis :allergens='ige.RValues'></RepDis>
        <br />
      </v-flex>
      <v-flex xs12>
        <div class='body-2'>
          Remarks:
        </div>
        <div class='body-2 font-weight-regular'>
          {{ ige.Remarks }}
        </div>
        <br />
        <br />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import RepDis from './RepDis';
export default {
  components: { RepDis },
  props: {
    ige: {
      type: Object,
      default: () => {
        return {
          SampleDate: '',
          LabIn: '',
          RTitle: '',
          RValues: [],
          Remarks: ''
        };
      }
    },
    fixedHeight: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
