<template>
  <v-container
    :style='fixedHeight ? "height: 400px;overflow: auto" : ""'
    grild-list-xl
    fluid
  >
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 v-if='checkProblems()'>
        <div class='title'>
          No data found
        </div>
      </v-flex>

      <v-flex
        v-else
        xs12
        sm12
        md12
        lg12
        v-for='(problem, index) in problems.Problems.Object'
        :key='index'
      >
        <div>
          <div class='subheading font-weight-medium problemHeader'>
            {{ problem.Name }}
          </div>
          <v-list>
            <v-list-item v-for='option in problem.Choices' :key='option'>
              <v-list-item-avatar>&#x2713;</v-list-item-avatar>
              <v-list-item-content>{{ option }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <div class='problemHeader' v-if='problem.Name !== "Others"'>
            <v-text-field
              label='Others:'
              readonly
              v-model='problem.Others'
            ></v-text-field>
          </div>
          <div class='problemHeader'>
            <v-text-field
              readonly
              label='Since:'
              v-model='problem.Since'
            ></v-text-field>
          </div>
          <div class='problemHeader'>
            <v-slider
              readonly
              v-model='problem.Scale'
              :tick-labels='tickLabels'
              :max='10'
              step='1'
              ticks='always'
              thumb-label='always'
              :thumb-size='24'
              label='Severity:'
            >
            </v-slider>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    problems: {
      type: Object,
      default: () => {
        return { Problems: [] };
      }
    },
    fixedHeight: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tickLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      checked: true
    };
  },
  methods: {
    checkProblems() {
      if (!this.problems.Problems) return true;
      if (
        this.problems.Problems === null ||
        this.problems.Problems.Object === null
      )
        return true;
      else return false;
    }
  }
};
</script>

<style scoped>
.problemHeader {
  margin-left: 15px;
}
</style>
