<template>
  <v-container :style='fixedHeight ? "height: 400px;overflow: auto" : ""'>
    <v-text-field
      v-model.trim='patient.Name'
      label='Full Name:'
      readonly
    ></v-text-field>
    <v-text-field
      v-model='patient.PatientId'
      readonly
      label='Patient Id:'
    ></v-text-field>
    <v-text-field
      readonly
      v-model.trim='patient.Email'
      label='Email:'
    ></v-text-field>
    <v-text-field
      readonly
      v-model.trim='patient.Mobile'
      label='Mobile Number:'
    ></v-text-field>
    <v-textarea
      readonly
      v-model.trim='patient.Address'
      label='Address:'
      no-resize
      rows='2'
    ></v-textarea>
    <v-text-field label='City:' v-model='patient.City' readonly> </v-text-field>
    <v-text-field label='State:' v-model='patient.State' readonly>
    </v-text-field>
    <v-text-field label='Country:' v-model='patient.Country' readonly>
    </v-text-field>
    <v-text-field label='PIN:' v-model='patient.PIN' readonly> </v-text-field>
    <v-text-field label='Age:' v-model.number='patient.Age' readonly>
    </v-text-field>
    <v-autocomplete
      readonly
      :items='genders'
      v-model='patient.Gender'
      label='Gender:'
      item-text='Text'
      item-value='Id'
    >
    </v-autocomplete>
  </v-container>
</template>

<script>
export default {
  props: {
    patient: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fixedHeight: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      genders: [
        { Id: 0, Text: 'Male' },
        { Id: 1, Text: 'Female' },
        { Id: 2, Text: 'Others' },
        { Id: 3, Text: 'Unknown' }
      ]
    };
  }
};
</script>

<style scoped></style>
