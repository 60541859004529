import { render, staticRenderFns } from "./InvestSummary.vue?vue&type=template&id=064b318c&scoped=true&"
import script from "./InvestSummary.vue?vue&type=script&lang=js&"
export * from "./InvestSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064b318c",
  null
  
)

export default component.exports